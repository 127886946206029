<template>
  <VCard>
    <VCardTitle>
      Добавить Администратора
    </VCardTitle>

    <VCardText>
      <VForm
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.uuid"
                label="UUID"
                :rules="uuidRules"
                @input="$emit('update:uuid', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        :disabled="!valid"
        @click="handleSubmit"
      >
        Добавить
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { version as uuidVersion, validate as uuidValidate } from 'uuid';
import { DEFAULT_USER } from './common';

export default {
  name: 'UserAddForm',

  props: {
    entity: {
      type: Object,
      default: () => ({ ...DEFAULT_USER }),
    },
  },

  data() {
    return {
      valid: true,
      uuidRules: [
        (v) => !!v || 'Введите UUID',
        (v) => (uuidValidate(v) && uuidVersion(v) === 4) || 'Некорректный UUID',
      ],
    };
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.$emit('submit', this.entity);
      }
    },
  },
};
</script>
